// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'stylesheets/application.scss'

import $ from 'jquery'
import popper from 'popper.js'
import bootstrap from 'bootstrap'

$(function () {
  $('[data-toggle="popover"]').popover()
})

jQuery.fn.selectText = function(){
    var doc = document;
    var element = this[0];
    if (doc.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
};

$(function() {
  // mcb#show
  if($("button.js--select-table").length > 0) {
    $("button.js--select-table").on("click", function(evt) {
      jQuery("tbody").selectText();
      document.execCommand("copy");
    });

    // get file inputs showing
    document.querySelector('.custom-file-input').addEventListener('change',function(e){
      var fileName = e.target.files[0].name
      var nextSibling = e.target.nextElementSibling
      nextSibling.innerText = fileName
    })
  }
});


// mapbox stuff
$(function() {
  // see if we're needed on this page
  if ($("#mapbox-map.costco-map").length == 0) { return; }

  mapboxgl.accessToken = 'pk.eyJ1Ijoia3VsaWt1bGkiLCJhIjoidVVYRmtybyJ9.1dQ4dawNGJpME2DuRY6ihQ';
  const map = new mapboxgl.Map({
    container: 'mapbox-map',
    style: 'mapbox://styles/mapbox/light-v11',
    bounds: [[-112.73294761283307,31.791183293178193],[-126.00104208959158,42.39997302097993]]
  });
  window.mbmap = map;

  map.on('load', () => {
    map.addSource('costcos', {
      type: 'geojson',
      data: '/costcos.geojson'
    });

    map.addSource('heatmap-data', {
      type: 'geojson',
      data: '/heatmap.geojson'
    });

    map.addLayer({
      'id': 'retailers',
      'type': 'circle',
      'source': 'heatmap-data',
      'minzoom': 8,
      paint: { "circle-color": "black", "circle-radius": 5 }
    });

    map.on('click', ['costcos','retailers'], (e) => {
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const props = e.features[0].properties;
      var description = "";
      if('retailer' in props) {
        // wfm or walmart
        description = props.retailer + " " + props.store_number + " - " + props.store_name;
      } else {
        description = "Costco " + props.warehouse_number + " - " + props.warehouse_name;
      }


      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
    });

    // heatmap stuff
    map.addLayer({
      type: "heatmap",
      id: "retailer-heatmap",
      source: "heatmap-data",
      'max-zoom': 14,
      paint: {
        'heatmap-color': [
          "interpolate",
          ["exponential", 1],
          ["heatmap-density"],
          0,
          "hsla(0, 0%, 0%, 0)",
          0.33,
          "hsl(63, 95%, 54%)",
          0.67,
          "hsl(5, 56%, 61%)",
          1,
          "hsl(279, 100%, 31%)"
        ],
        'heatmap-opacity': 0.7,
        'heatmap-radius': [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          16,
          5,
          19.2,
          10,
          24,
          22,
          32
        ],
        'heatmap-weight': [
          "interpolate",
          ["linear"],
          ["get", "quantity"],
          160,
          1,
          300,
          50,
          400,
          100
        ],
      },
    });

    map.addLayer({
      'id': 'costcos',
      'type': 'circle',
      'source': 'costcos',
      paint: { "circle-color": "#FF10F0", "circle-radius": 7 }
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', ['costcos','retailers'], () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', ['costcos','retailers'], () => {
      map.getCanvas().style.cursor = '';
    });
  });
});